// --- Vendors ---
import 'jquery-touchswipe';
// --- Components ---
const histoire = {
    readyFn: function () {
        console.log("histoire");
        let max = parseInt($(".grid-date").attr("data-max"), 10);

        $(".next").on("click", function () {
            console.log("je clique sur next purée");
            console.log("le max : " + max);


            // Récupère la valeur de l'attribut 'data' et la convertit en nombre
            let index = parseInt($(".grid-date").attr("data"), 10);
            console.log(index + " < " + max);

            if (index < max) {
                $("#histoire .cell-wrapper-texte").fadeOut();
                setTimeout(function () {
                    console.log(index + " < " + max);
                    index++;
                    $(".grid-date").attr("data", index);  // Met à jour l'attribut 'data'

                    // Supprime toutes les classes qui commencent par "actif-"
                    $(".grid-date").removeClass(function (idx, className) {
                        return (className.match(/\bactif-\S+/g) || []).join(' ');
                    });

                    // Ajoute la nouvelle classe basée sur l'index
                    $(".grid-date").addClass("actif-" + index);

                    // Change la date active
                    $(".cell-date.date-actif").removeClass("date-actif");
                    let dateActive = index + 3;
                    $(".cell-date-" + dateActive).addClass("date-actif");

                    let indexTexte = index + 1;
                    console.log("grid-texte-" + indexTexte);
                    $(".grid-texte").removeClass("actif");
                    $(".grid-texte-" + indexTexte).addClass("actif");
                }, 300);
                $("#histoire .cell-wrapper-texte").fadeIn();
            }
        });

        $(".precedent").on("click", function () {
            console.log("je clique sur précédent");


            // Récupère la valeur de l'attribut 'data' et la convertit en nombre
            let index = parseInt($(".grid-date").attr("data"), 10);
            console.log(index + " < " + max);

            if (index > 0) {
                $("#histoire .cell-wrapper-texte").fadeOut();
                setTimeout(function () {
                    console.log(index + " > 0");
                    index = index - 1;
                    $(".grid-date").attr("data", index);  // Met à jour l'attribut 'data'

                    // Supprime toutes les classes qui commencent par "actif-"
                    $(".grid-date").removeClass(function (idx, className) {
                        return (className.match(/\bactif-\S+/g) || []).join(' ');
                    });

                    // Ajoute la nouvelle classe basée sur l'index
                    $(".grid-date").addClass("actif-" + index);
                    // Change la date active
                    $(".cell-date.date-actif").removeClass("date-actif");
                    let dateActive = index + 3;
                    $(".cell-date-" + dateActive).addClass("date-actif");

                    let indexTexte = index + 1;
                    console.log("grid-texte-" + indexTexte);
                    $(".grid-texte").removeClass("actif");
                    $(".grid-texte-" + indexTexte).addClass("actif");
                }, 300);
                $("#histoire .cell-wrapper-texte").fadeIn();
            }


        });

        $("#histoire").swipe({

            swipeRight: function (event, direction, distance, duration, fingerCount, fingerData) {
                console.log("je swiper sur next purée");
                console.log("le max : " + max);


                // Récupère la valeur de l'attribut 'data' et la convertit en nombre
                let index = parseInt($(".grid-date").attr("data"), 10);
                console.log(index + " < " + max);

                if (index < max) {
                    $("#histoire .cell-wrapper-texte").fadeOut();
                    setTimeout(function () {
                        console.log(index + " < " + max);
                        index++;
                        $(".grid-date").attr("data", index);  // Met à jour l'attribut 'data'

                        // Supprime toutes les classes qui commencent par "actif-"
                        $(".grid-date").removeClass(function (idx, className) {
                            return (className.match(/\bactif-\S+/g) || []).join(' ');
                        });

                        // Ajoute la nouvelle classe basée sur l'index
                        $(".grid-date").addClass("actif-" + index);

                        // Change la date active
                        $(".cell-date.date-actif").removeClass("date-actif");
                        let dateActive = index + 3;
                        $(".cell-date-" + dateActive).addClass("date-actif");

                        let indexTexte = index + 1;
                        console.log("grid-texte-" + indexTexte);
                        $(".grid-texte").removeClass("actif");
                        $(".grid-texte-" + indexTexte).addClass("actif");
                    }, 300);
                }
            },
            swipeLeft: function (event, direction, distance, duration, fingerCount, fingerData) {

                console.log("je clique sur précédent");

                // Récupère la valeur de l'attribut 'data' et la convertit en nombre
                let index = parseInt($(".grid-date").attr("data"), 10);
                console.log(index + " < " + max);

                if (index > 0) {
                    $("#histoire .cell-wrapper-texte").fadeOut();
                    setTimeout(function () {
                        console.log(index + " > 0");
                        index = index - 1;
                        $(".grid-date").attr("data", index);  // Met à jour l'attribut 'data'

                        // Supprime toutes les classes qui commencent par "actif-"
                        $(".grid-date").removeClass(function (idx, className) {
                            return (className.match(/\bactif-\S+/g) || []).join(' ');
                        });

                        // Ajoute la nouvelle classe basée sur l'index
                        $(".grid-date").addClass("actif-" + index);
                        // Change la date active
                        $(".cell-date.date-actif").removeClass("date-actif");
                        let dateActive = index + 3;
                        $(".cell-date-" + dateActive).addClass("date-actif");

                        let indexTexte = index + 1;
                        console.log("grid-texte-" + indexTexte);
                        $(".grid-texte").removeClass("actif");
                        $(".grid-texte-" + indexTexte).addClass("actif");
                    }, 300);
                    $("#histoire .cell-wrapper-texte").fadeIn();

                }
            }
        });

        // Fonction pour vérifier si l'élément est visible
        function isElementInViewport(el) {
            var rect = el[0].getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        // Vérifier le défilement de la page
        $(window).on('scroll', function () {
            var video = $('#videomap');

            if (isElementInViewport(video)) {
                if (video[0].paused) {
                    video[0].play();
                }
            } else {
                if (!video[0].paused) {
                    video[0].pause();
                }
            }
        });

        // Initialiser la vérification lors du chargement de la page
        $(window).trigger('scroll');


    }
}
export { histoire };